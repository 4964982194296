import EmployeeBalancesUsed from "./EmployeeBalancesUsed";
import EmployeeBalancesUsedsFilter from "./EmployeeBalancesUsedsFilter";

export default class EmployeeBalancesUseds {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.employeeBalancesUsedsData = [];
    this.employeeBalancesUsed = new EmployeeBalancesUsed();
    this.filterData = new EmployeeBalancesUsedsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.employeeBalancesUsedsData = data.employeeBalancesUsedDatas;
  }
}
