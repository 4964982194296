<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <EmployeeBalancesUsedFilter
      :theFilterData="employeeBalancesUseds.filterData"
      v-on:search="search($event)"
    />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        employeeBalancesUseds.employeeBalancesUsedsData != undefined &&
        employeeBalancesUseds.employeeBalancesUsedsData.length != 0
      "
    >
      <EmployeeBalancesUsedsCards
        :employeeBalancesUsedData="
          employeeBalancesUseds.employeeBalancesUsedsData
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeBalancesUsedFilter from "@/components/employees/employeeBalancesUseds/EmployeeBalancesUsedFilter.vue";
import EmployeeBalancesUsedsCards from "@/components/employees/employeeBalancesUseds/EmployeeBalancesUsedsCards.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import EmployeeBalancesUseds from "@/models/employees/employeeBalancesUseds/EmployeeBalancesUseds";
import { getCurrentFullDate } from "@/utils/functions";

export default {
  name: "EmployeeBalancesUseds",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeBalancesUsedFilter,
    EmployeeBalancesUsedsCards,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeBalancesUseds: new EmployeeBalancesUseds(),
    };
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  methods: {
    // changePage(page) {
    //   this.employeeBalancesUseds.pagination.selfPage = page;
    //   this.getAllEmployeeBalancesUseds();
    // },
    getCurrentFullDate,
    search(data) {
      this.employeeBalancesUseds.filterData.fillData(data);
      this.employeeBalancesUseds.employeeBalancesUsedsData = [];
      this.getAllEmployeeBalancesUseds();
    },
    async getAllEmployeeBalancesUseds() {
      this.isLoading = true;
      try {
        const response =
          await this.employeeBalancesUseds.employeeBalancesUsed.getAllEmployeeBalancesUseds(
            this.language,
            this.userAuthorizeToken,
            this.employeeBalancesUseds.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeeBalancesUseds.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeeBalancesUseds.employeeBalancesUsedsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeeBalancesUseds.employeeBalancesUsedsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeeBalancesUseds.employeeBalancesUsedsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeeBalancesUseds.employeeBalancesUsedsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.employeeToken) {
      this.employeeBalancesUseds.filterData.employeeToken = this.employeeToken;
    }
    this.employeeBalancesUseds.filterData.year = this.getCurrentFullDate().year;
    this.getAllEmployeeBalancesUseds();
  },
};
</script>

<style lang="scss"></style>
