export default class EmployeeBalancesUsedsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeToken = "";
    this.year = "";
  }
  fillData(data) {
    this.employeeToken = data.employeeToken;
    this.year = data.year;
  }
}
