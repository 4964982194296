import axios from "axios";
import EmployeeBalancesUsedsFilter from "./EmployeeBalancesUsedsFilter";
import EmployeeBalanceStatistic from "./EmployeeBalanceStatistic";

export default class EmployeeBalancesUsed {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.employeeSalarySettingToken = "";
    this.employeeSalarySettingNameCurrent = "";
    this.balancesPlansToken = "";
    this.balancesPlansNameCurrent = "";
    this.employeeBalanceStatisticsData = [new EmployeeBalanceStatistic()];
  }
  fillData(data) {
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeSalarySettingToken = data.employeeSalarySettingToken;
    this.employeeSalarySettingNameCurrent =
      data.employeeSalarySettingNameCurrent;
    this.balancesPlansToken = data.balancesPlansToken;
    this.balancesPlansNameCurrent = data.balancesPlansNameCurrent;
    this.employeeBalanceStatisticsData.fillData(
      data.employeeBalancesUsedDatas.employeeBalanceStatisticsData
    );
  }

  async getAllEmployeeBalancesUseds(
    language,
    userAuthorizeToken,
    filterData = new EmployeeBalancesUsedsFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeeBalancesUsed/GetAllEmployeeBalancesUsed?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeToken=${filterData.employeeToken}&year=${filterData.year}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
}
