<template>
  <div class="custom-tab-card">
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBalancesUsedData.employeeSalarySettingNameCurrent"
        :title="$t('employeeSalarySetting')"
        :imgName="'settings.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBalancesUsedData.balancesPlansNameCurrent"
        :title="$t('balancesPlans')"
        :imgName="'balances-plans.svg'"
      />
    </div>

    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("salaryEffectName") }}</th>
            <th>{{ $t("maximumNumberInDay") }}</th>
            <th>{{ $t("maximumNumberInMonth") }}</th>
            <th>{{ $t("maximumNumberInYear") }}</th>
            <th>
              {{
                $t("EmployeeBalancesUseds.employeeBalanceStatistics.totalUsed")
              }}
            </th>
            <th>
              {{
                $t(
                  "EmployeeBalancesUseds.employeeBalanceStatistics.totalRemainder"
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              Balance, index
            ) in employeeBalancesUsedData.employeeBalanceStatisticsData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(Balance.employeeSalaryEffectNameCurrent) }}
            </td>
            <td>
              {{
                convertTimeAndDaysToString(
                  Balance.maximumNumberInDayInSeconds,
                  Balance.maximumNumberInDayInMinutes,
                  Balance.maximumNumberInDayInHours,
                  0
                )
              }}
            </td>
            <td>
              {{
                convertTimeAndDaysToString(
                  Balance.maximumNumberInMonthInSeconds,
                  Balance.maximumNumberInMonthInMinutes,
                  Balance.maximumNumberInMonthInHours,
                  Balance.maximumNumberInMonthInDays
                )
              }}
            </td>
            <td>
              {{
                convertTimeAndDaysToString(
                  Balance.maximumNumberInYearInSeconds,
                  Balance.maximumNumberInYearInMinutes,
                  Balance.maximumNumberInYearInHours,
                  Balance.maximumNumberInYearInDays
                )
              }}
            </td>
            <td>
              {{
                convertTimeAndDaysToString(
                  Balance.totalUsedBySeconds,
                  Balance.totalUsedByMinutes,
                  Balance.totalUsedByHours,
                  Balance.totalUsedByDays
                )
              }}
            </td>
            <td>
              {{
                convertTimeAndDaysToString(
                  Balance.totalRemainderBySeconds,
                  Balance.totalRemainderByMinutes,
                  Balance.totalRemainderByHours,
                  Balance.totalRemainderByDays
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { isDataExist, convertTimeAndDaysToString } from "@/utils/functions";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "SalaryInquiriesCards",
  mixins: [createToastMixin],
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["employeeBalancesUsedData"],
  methods: {
    isDataExist,
    convertTimeAndDaysToString(seconds, minutes, hours, days) {
      return convertTimeAndDaysToString(seconds, minutes, hours, days).data.data
        .string;
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
