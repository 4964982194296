export default class BanksFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeSalaryEffectToken = "";
    this.employeeSalaryEffectNameCurrent = "";
    this.maximumNumberInYearInDays = "";
    this.maximumNumberInYearInHours = "";
    this.maximumNumberInYearInMinutes = "";
    this.maximumNumberInYearInSeconds = "";
    this.maximumNumberInMonthInDays = "";
    this.maximumNumberInMonthInHours = "";
    this.maximumNumberInMonthInMinutes = "";
    this.maximumNumberInMonthInSeconds = "";
    this.maximumNumberInDayInHours = "";
    this.maximumNumberInDayInMinutes = "";
    this.maximumNumberInDayInSeconds = "";
    this.totalMaximumNumberInMillisecond = "";
    this.totalUsedByDays = "";
    this.totalUsedByHours = "";
    this.totalUsedByMinutes = "";
    this.totalUsedBySeconds = "";
    this.totalUsedByMilleSeconds = "";
    this.totalRemainderByDays = "";
    this.totalRemainderByHours = "";
    this.totalRemainderByMinutes = "";
    this.totalRemainderBySeconds = "";
    this.totalRemainderByMilleSeconds = "";
  }
  fillData(data) {
    this.employeeSalaryEffectToken = data.employeeSalaryEffectToken;
    this.employeeSalaryEffectNameCurrent = data.employeeSalaryEffectNameCurrent;
    this.maximumNumberInYearInDays = data.maximumNumberInYearInDays;
    this.maximumNumberInYearInHours = data.maximumNumberInYearInHours;
    this.maximumNumberInYearInMinutes = data.maximumNumberInYearInMinutes;
    this.maximumNumberInYearInSeconds = data.maximumNumberInYearInSeconds;
    this.maximumNumberInMonthInDays = data.maximumNumberInMonthInDays;
    this.maximumNumberInMonthInHours = data.maximumNumberInMonthInHours;
    this.maximumNumberInMonthInMinutes = data.maximumNumberInMonthInMinutes;
    this.maximumNumberInMonthInSeconds = data.maximumNumberInMonthInSeconds;
    this.maximumNumberInDayInHours = data.maximumNumberInDayInHours;
    this.maximumNumberInDayInMinutes = data.maximumNumberInDayInMinutes;
    this.maximumNumberInDayInSeconds = data.maximumNumberInDayInSeconds;
    this.totalMaximumNumberInMillisecond = data.totalMaximumNumberInMillisecond;
    this.totalUsedByDays = data.totalUsedByDays;
    this.totalUsedByHours = data.totalUsedByHours;
    this.totalUsedByMinutes = data.totalUsedByMinutes;
    this.totalUsedBySeconds = data.totalUsedBySeconds;
    this.totalUsedByMilleSeconds = data.totalUsedByMilleSeconds;
    this.totalRemainderByDays = data.totalRemainderByDays;
    this.totalRemainderByHours = data.totalRemainderByHours;
    this.totalRemainderByMinutes = data.totalRemainderByMinutes;
    this.totalRemainderBySeconds = data.totalRemainderBySeconds;
    this.totalRemainderByMilleSeconds = data.totalRemainderByMilleSeconds;
  }
}
